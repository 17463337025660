/**
 * checkValidEmail
 *
 * @source https://stackoverflow.com/a/46181
 * @param {String} email
 */

const checkValidEmail = (email) => {
  const regex = /\S+@\S+\.\S+/
  if (regex.test(String(email).toLowerCase())) {
    return true
  }
  return false
}

export { checkValidEmail }
