// extracted by mini-css-extract-plugin
export var Header = "s_bw";
export var bg = "s_bx";
export var clickDisable = "s_by";
export var main = "s_c";
export var isIntroActive = "s_bz";
export var isMenuOpen = "s_bB";
export var inner = "s_n";
export var logo = "s_bC";
export var logo__inner = "s_bD";
export var logo__svg = "s_bF";
export var logo__lineLeft = "s_bG";
export var logo__lineRight = "s_bH";
export var lineExtendedLeft = "s_bJ";
export var lineExtendedRight = "s_bK";
export var lineFillLeft = "s_bL";
export var lineFillRight = "s_bM";
export var nav = "s_bN";
export var btnMenu = "s_bP";
export var intro = "s_bQ";
export var intro__text = "s_bR";
export var aniSpin = "s_d";