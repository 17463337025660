import React, { useRef } from 'react'
import { useRecoilState } from 'recoil'
import { menuState } from '@/store'
import { useToggleTransition } from './BtnMenuIcon.gsap'
import * as styles from './BtnMenuIcon.styles.scss'

const BtnMenuIcon = () => {
  const ref = useRef()
  const [isMenuOpen, setIsMenuOpen] = useRecoilState(menuState)
  const handleClickMenu = () => setIsMenuOpen((prev) => !prev)

  useToggleTransition(ref, {
    isActive: isMenuOpen,
    selectors: {
      iconOpen: `.${styles.iconOpen}`,
      iconClose: `.${styles.iconClose}`,
    },
  })

  return (
    <button
      type="button"
      onClick={handleClickMenu}
      className={styles.BtnMenuIcon}
      ref={ref}
      aria-expanded={isMenuOpen}
      aria-controls="menu" // references a menu id attribute
      aria-label={`${isMenuOpen ? 'Close' : 'Open'} menu`}
    >
      <span className={styles.iconClose} aria-hidden={!isMenuOpen}>
        <span className={styles.iconClose__line} />
        <span className={styles.iconClose__line} />
      </span>
      <span className={styles.iconOpen} aria-hidden={isMenuOpen}>
        <span className={styles.iconOpen__inner}>
          <span className={styles.iconOpen__line} />
          <span className={styles.iconOpen__line} />
          <span className={styles.iconOpen__line} />
        </span>
      </span>
    </button>
  )
}

export { BtnMenuIcon }
