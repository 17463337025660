// extracted by mini-css-extract-plugin
export var Container = "r_bb";
export var gridRowD = "r_bc";
export var gridRowM = "r_bd";
export var gridColD1 = "r_bf";
export var gridColD2 = "r_bg";
export var gridColD3 = "r_bh";
export var gridColD4 = "r_bj";
export var gridColD5 = "r_bk";
export var gridColD6 = "r_bl";
export var gridColD7 = "r_bm";
export var gridColD8 = "r_bn";
export var gridColD9 = "r_bp";
export var gridColD10 = "r_bq";
export var gridColD11 = "r_br";
export var gridColD12 = "r_bs";
export var gridColM1 = "r_bt";
export var gridColM2 = "r_bv";
export var aniSpin = "r_d";