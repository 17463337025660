import { atom } from 'recoil'

/**
 * For use with Cursor
 *
 * @usage
 * const setCursor = useSetRecoilState(cursorState)
 */

export const cursorState = atom({
  key: 'cursorState', // unique ID (with respect to other atoms/selectors)
  default: { isHovering: 'none', isClicking: 'none' },
})
