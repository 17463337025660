import { atom } from 'recoil'

/**
 * Store transition state here
 *
 * @usage
 * const [introComplete, setIntroComplete] = useRecoilState(introState)
 */

export const introState = atom({
  key: 'introState', // unique ID (with respect to other atoms/selectors)
  default: false,
})
