import React, { useRef } from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import { useRecoilState, useRecoilValue } from 'recoil'
import { useBodyScrollLock } from '@/hooks/useBodyScrollLock'
import { introState, menuState, browserState, fontsLoadedState } from '@/store'
import { Container } from '@/components/Container'
import { RichText } from '@/components/RichText'
import { Link } from '@/components/Link'
import { BtnMenuText } from '@/components/BtnMenuText'
import { BtnMenuIcon } from '@/components/BtnMenuIcon'
import LogoSvg from '@/assets/logo.inline.svg'
import { useIntro } from './Header.gsap'
import * as styles from './Header.styles.scss'

const Header = ({ title, intro }) => {
  const { device } = useRecoilValue(browserState)
  const [introComplete, setIntroComplete] = useRecoilState(introState)
  const isFontsLoaded = useRecoilValue(fontsLoadedState)
  const ref = useRef()
  const mainRef = useRef()
  const isMenuOpen = useRecoilValue(menuState)
  useBodyScrollLock(mainRef, !introComplete, { reserveScrollBarGap: true })

  useIntro(ref, {
    selectors: {
      lineFillLeft: `.${styles.lineFillLeft}`,
      lineFillRight: `.${styles.lineFillRight}`,
      lineExtendedLeft: `.${styles.lineExtendedLeft}`,
      lineExtendedRight: `.${styles.lineExtendedRight}`,
      nav: `.${styles.nav}`,
      bg: `.${styles.bg}`,
      clickDisable: `.${styles.clickDisable}`,
      introText: `.${styles.intro__text}`,
      logo: `.${styles.logo}`,
      main: `.${styles.main}`,
    },
    callback: () => setIntroComplete(true),
    isReady: isFontsLoaded,
    hasTransitioned: introComplete,
  })

  return (
    <header
      className={classnames(styles.Header, { [styles.isMenuOpen]: isMenuOpen })}
      ref={ref}
    >
      <div className={styles.clickDisable} />
      <div className={styles.bg} />
      <div
        className={classnames(styles.main, {
          [styles.isIntroActive]: !introComplete,
        })}
        ref={mainRef}
      >
        <Container>
          <div className={styles.inner}>
            <div className={styles.logo}>
              <h1 className={styles.logo__inner}>
                <Link to="/" aria-label={title}>
                  <LogoSvg className={styles.logo__svg} />
                  <span className={styles.logo__lineLeft} aria-hidden>
                    <span className={styles.lineFillLeft} />
                    <span className={styles.lineExtendedLeft} />
                  </span>
                  <span className={styles.logo__lineRight} aria-hidden>
                    <span className={styles.lineFillRight} />
                    <span className={styles.lineExtendedRight} />
                  </span>
                </Link>
              </h1>
              {intro && (
                <div className={styles.intro}>
                  <Container>
                    <div className={styles.intro__text}>
                      <RichText>{intro}</RichText>
                    </div>
                  </Container>
                </div>
              )}
            </div>
            <nav className={styles.nav}>
              <div className={styles.btnMenu}>
                {device === 'mobile' ? <BtnMenuIcon /> : <BtnMenuText />}
              </div>
            </nav>
          </div>
        </Container>
      </div>
    </header>
  )
}

Header.propTypes = {
  title: PropTypes.string,
  intro: PropTypes.node,
}

export { Header }
