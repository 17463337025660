import React, { forwardRef } from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import { useRecoilValue } from 'recoil'
import { cursorState } from '@/store'
import ArrowLeft from '@/assets/arrow-left.inline.svg'
import ArrowRight from '@/assets/arrow-right.inline.svg'
import * as styles from './Cursor.styles.scss'

const Cursor = forwardRef((props, ref) => {
  const cursor = useRecoilValue(cursorState)
  const showPrev = cursor.isHovering === 'prev' || cursor.isClicking === 'prev'
  const showNext = cursor.isHovering === 'next' || cursor.isClicking === 'next'

  return (
    <div ref={ref} className={styles.Cursor}>
      <div
        className={classnames(styles.inner, {
          [styles.isClicking]: cursor.isClicking !== 'none',
        })}
      >
        <ArrowLeft
          className={classnames(styles.arrow, { [styles.isVisible]: showPrev })}
        />
        <ArrowRight
          className={classnames(styles.arrow, { [styles.isVisible]: showNext })}
        />
        <div className={styles.debug} aria-hidden="true">
          Hover: {cursor.isHovering}
          <br />
          Click: {cursor.isClicking}
        </div>
      </div>
    </div>
  )
})

Cursor.propTypes = {
  children: PropTypes.node,
}

export { Cursor }
