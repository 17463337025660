import { useCallback, useEffect, useRef, useState } from 'react'
import { useEventListener } from '@/hooks/useEventListener'
import { cursorState } from '@/store'
import { useSetRecoilState } from 'recoil'

/**
 * Custom Cursor
 * Replaces the native cursor with a custom animated cursor, consisting
 * of an inner and outer dot that scale inversely based on hover or click.

 * https://github.com/stephenscaff/react-animated-cursor/blob/master/lib/AnimatedCursor.js
 */

export const useCursor = () => {
  const cursorRef = useRef()
  const [isVisible, setIsVisible] = useState(false)
  let endX = useRef(0)
  let endY = useRef(0)
  const setCursor = useSetRecoilState(cursorState)

  const [prevHoverState, setPrevHoverState] = useState('none')
  const [prevClickState, setPrevClickState] = useState('none')
  // const prevCursorState = useRef({ isClicking: 'none', isHovering: 'none' })

  // Primary Mouse Move event
  const onMouseMove = useCallback(({ clientX, clientY }) => {
    // cursorRef.current.style.top = `${clientY}px`
    // cursorRef.current.style.left = `${clientX}px`
    cursorRef.current.style.transform = `translateX(${clientX}px) translateY(${clientY}px)`
    endX.current = clientX
    endY.current = clientY
  }, [])

  const updateCursorState = useCallback(
    ({ isHovering, isClicking }) => {
      // const updateCursorState = ({ isHovering, isClicking }) => {
      const mergedCursorState = {
        isHovering: isHovering === undefined ? prevHoverState : isHovering,
        isClicking: isClicking === undefined ? prevClickState : isClicking,
      }

      setCursor(mergedCursorState)

      // update prev state
      setPrevHoverState((prev) => isHovering || prev)
      setPrevClickState((prev) => isClicking || prev)
    },
    [prevHoverState, prevClickState, setCursor]
  )

  // Mouse Events State updates
  // const onMouseDown = useCallback(() => {
  //   updateCursorState({ isClicking: 'default' })
  // }, [])

  // const onMouseUp = useCallback(() => {
  //   updateCursorState({ isClicking: 'none' })
  // }, [])

  const onMouseEnterViewport = useCallback(() => {
    setIsVisible(true)
  }, [])

  const onMouseLeaveViewport = useCallback(() => {
    setIsVisible(false)
  }, [])

  useEventListener('mousemove', onMouseMove)
  // useEventListener('mousedown', onMouseDown)
  // useEventListener('mouseup', onMouseUp)
  useEventListener('mouseover', onMouseEnterViewport)
  useEventListener('mouseout', onMouseLeaveViewport)

  // Cursor Visibility State
  useEffect(() => {
    // document.body.style.cursor = 'none'

    if (cursorRef.current) {
      cursorRef.current.style.opacity = isVisible ? 1 : 0
    }
  }, [isVisible])

  // Target all possible clickables
  useEffect(() => {
    let clickables = []

    setTimeout(() => {
      clickables = document.querySelectorAll(
        'a, input[type="submit"], input[type="image"], label[for], select, button, .link, [data-cursor]'
      )
      clickables.forEach((el) => {
        const cursorType = el.getAttribute('data-cursor') || 'default'
        el.style.cursor = el.getAttribute('data-cursor') ? 'none' : null

        el.addEventListener('mouseover', () => {
          updateCursorState({ isHovering: cursorType })
        })
        el.addEventListener('click', () =>
          updateCursorState({ isHovering: cursorType, isClicking: 'none' })
        )
        el.addEventListener('mousedown', () =>
          updateCursorState({ isClicking: cursorType })
        )
        el.addEventListener('mouseup', () =>
          updateCursorState({ isHovering: cursorType })
        )
        el.addEventListener('mouseout', () =>
          updateCursorState({ isHovering: 'none', isClicking: 'none' })
        )
      })
    }, 200)

    return () => {
      clickables.forEach((el) => {
        const cursorType = el.getAttribute('data-cursor') || 'default'
        el.removeEventListener('mouseover', () =>
          updateCursorState({ isHovering: cursorType })
        )
        el.removeEventListener('click', () =>
          updateCursorState({ isHovering: cursorType, isClicking: 'none' })
        )
        el.removeEventListener('mousedown', () =>
          updateCursorState({ isClicking: cursorType })
        )
        el.removeEventListener('mouseup', () =>
          updateCursorState({ isHovering: cursorType })
        )
        el.removeEventListener('mouseout', () =>
          updateCursorState({ isHovering: 'none', isClicking: 'none' })
        )
      })
    }
  }, [updateCursorState])

  return cursorRef
}
