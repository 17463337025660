import React from 'react'
import { isMobileDevice } from '@/utils/isMobileDevice'
import { Cursor } from '@/components/Cursor'
import { useCursor } from '@/hooks/useCursor'

const CursorWrap = () => {
  const cursorRef = useCursor()
  return <Cursor ref={cursorRef} />
}

/**
 * Custom Cursor
 */

const CursorService = () => {
  if (typeof navigator !== 'undefined' && isMobileDevice.any()) {
    return <React.Fragment></React.Fragment>
  }

  return <CursorWrap />
}

export { CursorService }
