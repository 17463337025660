import { useStaticQuery, graphql } from 'gatsby'
import { get } from '@/utils/get'
import { getLink } from '@/storyblok/utils/getLink'
import faviconImg from '@/assets/favicon.png'
import faviconDarkmodeImg from '@/assets/favicon-darkmode.png'
import { getRichText } from '@/storyblok/utils/getRichText'
// import { getSlug } from '@/utils/getSlug'

/**
 * Pull in Prismic config and fallback to Gatsby config
 */

export const useSiteConfig = () => {
  const data = useStaticQuery(
    graphql`
      query {
        gatsbyConfig: site {
          siteMetadata {
            title
            subtitle
            description
            author
            siteUrl
            image
          }
        }
        global: allStoryblokEntry(filter: { slug: { eq: "global" } }) {
          edges {
            node {
              id
              full_slug
              content
            }
          }
        }
      }
    `
  )

  const gatsbyConfig = data.gatsbyConfig.siteMetadata || {}
  const storyblokConfig = get(data, 'global.edges')
    ? JSON.parse(data.global.edges[0].node?.content)
    : {}

  const nav = []
  storyblokConfig.header_links.forEach((item, index) => {
    nav.push(
      getLink({
        id: `nav_${index}`,
        title: item.title,
        link: item.link,
      })
    )
  })

  const siteTitle = storyblokConfig.title || gatsbyConfig.title

  return {
    metadata: {
      title: siteTitle,
      description: storyblokConfig.description || gatsbyConfig.description,
      subtitle: storyblokConfig.subtitle || gatsbyConfig.subtitle,
      image: get(storyblokConfig, 'image.filename') || gatsbyConfig.image,
      siteUrl: gatsbyConfig.siteUrl,
    },
    favicon: {
      default: faviconImg,
      darkMode: faviconDarkmodeImg || faviconImg,
    },
    header: {
      nav,
      title: siteTitle,
      intro: getRichText(storyblokConfig.loader_description),
    },
    menu: {
      heading: storyblokConfig.info_heading,
      bodyText: getRichText(storyblokConfig.info_body),
      newsletter: {
        heading: storyblokConfig.newsletter_heading,
        successText: storyblokConfig.newsletter_confirmation,
        url: storyblokConfig.newsletter_endpoint,
      },
      footerText: getRichText(storyblokConfig.info_footer),
    },
    footer: {},
  }
}
