import { useEffect } from 'react'
import { gsap, Power3 } from 'gsap'

export const useToggleTransition = (ref, { selectors, isActive }) => {
  const { iconOpen, iconClose } = selectors

  useEffect(() => {
    let tl
    const $iconOpen = ref.current.querySelector(iconOpen)
    const $iconClose = ref.current.querySelector(iconClose)
    const transition = { duration: 0.75, ease: Power3.easeInOut }

    if (isActive) {
      tl = gsap.timeline()
      tl.set(ref.current, { pointerEvents: 'none' })
      tl.to($iconOpen, { opacity: 0, ...transition }, 0)
      tl.to($iconClose, { opacity: 1, ...transition }, 0)
      tl.set(ref.current, { pointerEvents: 'auto' })
    } else {
      tl = gsap.timeline()
      tl.set(ref.current, { pointerEvents: 'none' })
      tl.to($iconOpen, { opacity: 1, ...transition }, 0)
      tl.to($iconClose, { opacity: 0, ...transition }, 0)
      tl.set(ref.current, { pointerEvents: 'auto' })
    }

    return () => {
      if (tl) tl.kill()
    }
  }, [ref, isActive, iconOpen, iconClose])
}
