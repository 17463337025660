import { useEffect, useRef } from 'react'
import { gsap, Power3 } from 'gsap'

export const useToggleTransition = (ref, { isOpen, selectors }) => {
  const { bg, footer, headerGradient, heading, bodyText } = selectors
  const firstRender = useRef(true)

  useEffect(() => {
    const $bg = ref.current.querySelector(bg)
    const $headerGradient = ref.current.querySelector(headerGradient)
    const $heading = ref.current.querySelector(heading)
    const $bodyText = ref.current.querySelector(bodyText)
    const $footer = ref.current.querySelector(footer)

    const timeline = gsap.timeline({})

    if (firstRender.current) {
      gsap.set([$footer, $heading, $bodyText], { opacity: 0 })
    }

    firstRender.current = false

    if (isOpen) {
      // transition in
      timeline.fromTo(
        $bg,
        {
          scaleY: 0,
        },
        {
          duration: 0.6,
          scaleY: 1,
          ease: Power3.easeOut,
        },
        0
      )
      timeline.to(
        $footer,
        {
          duration: 2,
          opacity: 1,
          ease: Power3.easeOut,
        },
        0.3
      )
      timeline.fromTo(
        $bodyText,
        {
          opacity: 0,
        },
        {
          duration: 2,
          opacity: 1,
          ease: Power3.easeOut,
        },
        0.66
      )
      timeline.fromTo(
        $heading,
        {
          opacity: 0,
        },
        {
          duration: 2,
          opacity: 1,
          ease: Power3.easeOut,
        },
        0.66
      )
      timeline.to(
        $headerGradient,
        {
          duration: 0.6,
          opacity: 1,
          ease: Power3.easeOut,
        },
        0.66
      )
    } else {
      // transition out
      timeline.to(
        [$footer, $heading, $bodyText],
        {
          duration: 0.6,
          opacity: 0,
          ease: Power3.easeIn,
        },
        0
      )
      timeline.to(
        $headerGradient,
        {
          duration: 0.3,
          opacity: 0,
          ease: Power3.easeOut,
        },
        0.3
      )
      timeline.to(
        $bg,
        {
          duration: 0.6,
          scaleY: 0,
          ease: Power3.easeOut,
        },
        0.6
      )
    }

    return () => {
      if (timeline) timeline.kill()
    }
  }, [ref, isOpen, bg, footer, headerGradient, heading, bodyText])
}
