// extracted by mini-css-extract-plugin
export var Menu = "t_bS";
export var headerGradient = "t_bT";
export var scroll = "t_bV";
export var bg = "t_bx";
export var content = "t_P";
export var info = "t_bW";
export var heading = "t_N";
export var bodyText = "t_bX";
export var footer = "t_R";
export var newsletter = "t_bY";
export var footerText = "t_bZ";
export var aniSpin = "t_d";