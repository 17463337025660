import { useEffect } from 'react'
import { gsap, Power3 } from 'gsap'

export const useAnimationLoop = (ref, { selectors, isPlaying }) => {
  const { underline } = selectors

  useEffect(() => {
    let tl
    const $underline = ref.current.querySelector(underline)

    if (isPlaying) {
      tl = gsap.timeline({ repeat: -1, repeatDelay: 1, delay: 1.5 })
      tl.set($underline, { transformOrigin: 'top left' })
      tl.fromTo(
        $underline,
        { scaleX: 0, opacity: 0.5 },
        { scaleX: 1, opacity: 0.75, duration: 0.75, ease: Power3.easeInOut }
      )
      tl.set($underline, { transformOrigin: 'top right' })
      tl.to($underline, {
        scaleX: 0,
        opacity: 0.5,
        duration: 0.75,
        ease: Power3.easeInOut,
      })
    } else {
      tl = gsap.timeline()
      tl.to($underline, { transformOrigin: 'top left' })
      tl.to($underline, {
        scaleX: 0,
        opacity: 0,
        duration: 0.75,
        ease: Power3.easeInOut,
      })
    }

    return () => {
      if (tl) tl.kill()
    }
  }, [ref, isPlaying, underline])
}

export const useToggleTransition = (ref, { selectors, isActive }) => {
  const { textOpen, textClose } = selectors

  useEffect(() => {
    let tl
    const $textOpen = ref.current.querySelector(textOpen)
    const $textClose = ref.current.querySelector(textClose)
    const transition = { duration: 0.75, ease: Power3.easeInOut }

    if (isActive) {
      tl = gsap.timeline()
      tl.set(ref.current, { pointerEvents: 'none' })
      tl.to($textOpen, { y: '-100%', opacity: 0, ...transition }, 0)
      tl.to($textClose, { y: '0%', opacity: 1, ...transition }, 0)
      tl.set(ref.current, { pointerEvents: 'auto' })
    } else {
      tl = gsap.timeline({ delay: 0.2 })
      tl.set(ref.current, { pointerEvents: 'none' })
      tl.to($textOpen, { y: '0%', opacity: 1, ...transition }, 0)
      tl.to($textClose, { y: '100%', opacity: 0, ...transition }, 0)
      tl.set(ref.current, { pointerEvents: 'auto' })
    }

    return () => {
      if (tl) tl.kill()
    }
  }, [ref, isActive, textOpen, textClose])
}
