import { useEffect, useRef } from 'react'
import { gsap, Power3 } from 'gsap'

export const useIntro = (
  ref,
  { selectors, callback, hasTransitioned, isReady }
) => {
  const {
    bg,
    clickDisable,
    lineFillLeft,
    lineFillRight,
    lineExtendedLeft,
    lineExtendedRight,
    main,
    nav,
    introText,
    logo,
  } = selectors

  const isAnimating = useRef(false)

  useEffect(() => {
    const $bg = ref.current.querySelector(bg)
    const $clickDisable = ref.current.querySelector(clickDisable)
    const $lineFillLeft = ref.current.querySelector(lineFillLeft)
    const $lineFillRight = ref.current.querySelector(lineFillRight)
    const $lineExtendedLeft = ref.current.querySelector(lineExtendedLeft)
    const $lineExtendedRight = ref.current.querySelector(lineExtendedRight)
    const $nav = ref.current.querySelector(nav)
    const $introText = ref.current.querySelector(introText)
    const $logo = ref.current.querySelector(logo)
    const $main = ref.current.querySelector(main)

    if (isReady) {
      if (!hasTransitioned && !isAnimating.current) {
        isAnimating.current = true

        const windowHeight = window.innerHeight
        const logoHeight = $logo.clientHeight
        const logoShiftY = windowHeight / 2 - (logoHeight + 20)

        const tl = gsap.timeline({
          onComplete: () => {
            if (typeof callback === 'function') callback()
            isAnimating.current = false
          },
        })
        tl.set($main, { opacity: 1 })
        tl.set([$lineExtendedLeft, $lineExtendedRight, $bg, clickDisable], {
          display: 'block',
        })
        tl.set($logo, { y: logoShiftY })
        tl.to($logo, { opacity: 1, duration: 0.5 })
        tl.fromTo(
          $introText,
          { opacity: 0 },
          { opacity: 1, duration: 0.5, delay: 0.5 }
        )
        tl.fromTo(
          [$lineExtendedLeft, $lineExtendedRight],
          { scaleX: 0 },
          { scaleX: 1, duration: 2, ease: Power3.easeOut }
        )
        tl.fromTo(
          [$lineFillLeft, $lineFillRight],
          { opacity: 0 },
          { opacity: 1, duration: 0.33 }
        )
        tl.set($lineExtendedLeft, { transformOrigin: 'top right' })
        tl.set($lineExtendedRight, { transformOrigin: 'top left' })
        tl.to([$lineExtendedLeft, $lineExtendedRight], {
          scaleX: 0,
          duration: 2.5,
          ease: Power3.easeOut,
        })

        tl.to($logo, { y: 0, duration: 0.8, ease: Power3.easeInOut }, '=-1.2')

        tl.fromTo(
          $bg,
          { opacity: 1 },
          { opacity: 0, duration: 0.8, ease: Power3.easeInOut, delay: 0.2 },
          '=-1.5'
        )
        tl.fromTo(
          $introText,
          { opacity: 1 },
          {
            opacity: 0,
            duration: 0.8,
            ease: Power3.easeInOut,
            delay: 0.2,
            immediateRender: false,
          },
          '=-1.5'
        )
        tl.fromTo(
          $nav,
          { opacity: 0, pointerEvents: 'none' },
          { opacity: 1, duration: 0.33, pointerEvents: 'auto' },
          '=-1'
        )
        tl.set($clickDisable, { display: 'none' })
      }
    } else {
      gsap.set([$bg, clickDisable], { display: 'block' })
      gsap.set([$logo, $nav], { opacity: 0 })
    }
  }, [
    ref,
    callback,
    hasTransitioned,
    isReady,
    bg,
    clickDisable,
    lineFillLeft,
    lineFillRight,
    lineExtendedLeft,
    lineExtendedRight,
    main,
    nav,
    introText,
    logo,
  ])
}
