import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import { useRecoilValue } from 'recoil'
import { menuState, browserState } from '@/store'
import { useBodyScrollLock } from '@/hooks/useBodyScrollLock'
import { RichText } from '@/components/RichText'
import { MailchimpSubscribe } from '@/components/MailchimpSubscribe'
import { useToggleTransition } from './Menu.gsap'
import * as styles from './Menu.styles.scss'

const Menu = ({ heading, bodyText, newsletter, footerText }) => {
  const ref = useRef()
  const scrollRef = useRef()
  const isMenuOpen = useRecoilValue(menuState)
  const { device, viewportHeight } = useRecoilValue(browserState)

  useToggleTransition(ref, {
    isOpen: isMenuOpen,
    selectors: {
      bg: `.${styles.bg}`,
      headerGradient: `.${styles.headerGradient}`,
      heading: `.${styles.heading}`,
      bodyText: `.${styles.bodyText}`,
      footer: `.${styles.footer}`,
    },
  })
  useBodyScrollLock(scrollRef, isMenuOpen, { reserveScrollBarGap: true })
  const height = device === 'mobile' ? `${viewportHeight}px` : null

  return (
    <div
      className={styles.Menu}
      style={{
        pointerEvents: isMenuOpen ? 'auto' : 'none',
        height,
      }}
      id="menu" // referenced by BtnMenu
      ref={ref}
    >
      <div className={styles.headerGradient} />
      <div className={styles.bg} />
      <div className={styles.scroll} style={{ height }} ref={scrollRef}>
        <div className={styles.content}>
          <div></div>
          <div className={styles.info}>
            <h2 className={styles.heading}>{heading}</h2>
            <div className={styles.bodyText}>
              <RichText>{bodyText}</RichText>
            </div>
          </div>
          <div className={styles.footer}>
            {newsletter?.url && (
              <div className={styles.newsletter}>
                <div>
                  {newsletter.heading && <div>{newsletter.heading}</div>}
                  <MailchimpSubscribe {...newsletter} />
                </div>
              </div>
            )}
            {footerText && (
              <div className={styles.footerText}>{footerText}</div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

Menu.propTypes = {
  heading: PropTypes.node,
  bodyText: PropTypes.node,
  newsletter: PropTypes.object,
  footerText: PropTypes.node,
}

export { Menu }
