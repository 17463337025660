import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import { useRecoilState } from 'recoil'
import { menuState } from '@/store'
import { useAnimationLoop, useToggleTransition } from './BtnMenuText.gsap'
import * as styles from './BtnMenuText.styles.scss'

const BtnMenuText = ({ textOpen, textClose }) => {
  const ref = useRef()
  const [isMenuOpen, setIsMenuOpen] = useRecoilState(menuState)
  const handleClickMenu = () => setIsMenuOpen((prev) => !prev)

  useAnimationLoop(ref, {
    isPlaying: !isMenuOpen,
    selectors: { underline: `.${styles.underline}` },
  })

  useToggleTransition(ref, {
    isActive: isMenuOpen,
    selectors: {
      textOpen: `.${styles.textOpen}`,
      textClose: `.${styles.textClose}`,
    },
  })

  return (
    <button
      type="button"
      onClick={handleClickMenu}
      className={styles.BtnMenuText}
      ref={ref}
      aria-expanded={isMenuOpen}
      aria-controls="menu" // references a menu id attribute
    >
      <span className={styles.textClose} aria-hidden={!isMenuOpen}>
        {textClose}
      </span>
      <span className={styles.textOpen} aria-hidden={isMenuOpen}>
        {textOpen}
        <span className={styles.underline} />
      </span>
    </button>
  )
}

BtnMenuText.defaultProps = {
  textOpen: 'Info',
  textClose: 'Close',
}

BtnMenuText.propTypes = {
  textOpen: PropTypes.string,
  textClose: PropTypes.string,
}

export { BtnMenuText }
